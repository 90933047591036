.etc_container{padding-top: 108px !important;}
.etc_container .error_504{width: 1200px; margin: 0 auto; padding: 250px 0 369px; text-align: center;}
.etc_container .error_504 > h3{font-size: 20px; line-height: 25px; font-weight: 700;}
.etc_container .error_504 > p{font-size: 14px; margin-top: 16px; line-height: 17px; color: #666;}
.etc_container .error_504 > button{margin-top: 73px; width: 200px; height: 54px; border-radius: 8px; background: #f93964; font-size: 16px; color: #fff;}
.etc_container > h1{text-align: center; width: 1200px; margin: 0 auto; padding-top: 92px;}
.etc_container > h1 > a{width: 189px; display: inline-block;}
.etc_container > h1 > a > img{width: 100%;}
.etc_container .routine_inspection{width: 1200px; margin: 0 auto; padding: 120px 0; text-align: center;}
.etc_container .routine_inspection > h3{font-size: 20px; line-height: 25px; font-weight: 700;}
.etc_container .routine_inspection > p{font-size: 14px; margin-top: 16px; line-height: 17px; color: #666;}
.etc_container .routine_inspection .g_box{width: 480px; display: inline-block; margin-top: 40px; background: #fbfbfb; border-radius: 16px; padding: 30px 70px;}
.etc_container .routine_inspection .g_box > ul{width: 100%;}
.etc_container .routine_inspection .g_box > ul > li{width: 100%; margin-top: 15px; display: table;}
.etc_container .routine_inspection .g_box > ul > li:first-child{margin-top: 0;}
.etc_container .routine_inspection .g_box > ul > li > span{text-align: left; vertical-align: top; font-size: 14px; line-height: 20px; display: table-cell;}
.etc_container .routine_inspection .g_box > ul > li > span:first-child{font-weight: 700; padding-right: 30px; width: 79px;}

.header{width: 100%; position: fixed; background: #fff; z-index: 100; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);}
.etc_header{width: 100%; height: auto !important; padding: 60px 0 0 !important; box-shadow: none !important; border: 0 !important;}
.etc_header > h1{width: 1200px; margin: 0 auto; text-align: left;}
.etc_header > h1 > a{width: 189px; display: inline-block;}
.etc_header > h1 > a > img{width: 100%;}

.balance_container{width: 100%; padding: 40px 0 200px !important;}
.balance_header{width: 100%; padding: 200px 0 40px; text-align: center; min-width: 1200px;}
.balance_header > a{width: 189px; display: inline-block;}
.balance_header > a > img{width: 100%;}

.reject_emali{width: 720px; margin: 30px auto 0;}
.reject_emali > h1{padding-top: 100px; background: url(../../../public/assets/images/reject_email.svg) center top no-repeat; font-size: 20px; font-weight: 700; text-align: center;}
.reject_emali > p{margin-top: 16px; font-size: 14px; text-align: center; color: #666;}
.reject_emali .info_box{width: 100%; margin-top: 40px; background: #fbfbfb; border-radius: 16px; padding: 30px;}
.reject_emali .info_box > p{ padding-left: 7px; text-indent: -7px; font-size: 13px; color: #666; line-height: 19px; margin-top: 6px;}
.reject_emali .info_box > p > b{color: #000; font-weight: 500;}
.reject_emali .info_box > p:first-child{margin-top: 0;}

.etc_container .error_404{width: 1200px; margin: 0 auto; padding: 212px 0 369px; text-align: center;}
.etc_container .error_404 > h3{font-size: 20px; line-height: 26px; font-weight: 700; padding-top: 168px; background: url(../../../public/assets/images/404_img@2x.png)top center no-repeat; background-size: 204px; }
.etc_container .error_404 > p{font-size: 14px; margin-top: 16px; line-height: 17px; color: #666;}
.etc_container .error_404 > button{margin-top: 8px; width: 360px; height: 54px; border-radius: 8px; background: #999999; font-size: 16px; color: #fff;}
.etc_container .error_404 > button.home{margin-top: 50px; background: #f93964;}